<template>
  <a-table
    :columns="columns"
    :data-source="data"
    :loading="loadingTable"
    :scroll="{ x: 1300 }"
    :pagination="{ pageSize: 30, hideOnSinglePage: true }"
    bordered
  >
    <div slot="hubungan" slot-scope="value">{{showHubungan(value) === 'Wali' ? 'Wali (opsional)' : showHubungan(value)}}</div>
    <span slot="nullStr" slot-scope="text">
      <span v-if="!text">-</span>
      <span v-else>{{text}}</span>
    </span>
    <span slot="pendidikan" slot-scope="text">
      <span v-if="!text">-</span>
      <span v-else>{{setValueTable(text.deskripsi, 'educationsList')}}</span>
    </span>
    <span slot="penghasilan" slot-scope="text">
      <span v-if="!text">-</span>
      <span v-else>{{setValueTable(text.deskripsi, 'monthlyRevenueList')}}</span>
    </span>
    <span slot="berkebutuhan_khusus" slot-scope="value, record">
      <a-tag v-if="!record.berkebutuhan_khusus" color="green">Tidak</a-tag>
      <template v-else>
        <a-tag v-if="!record.berkebutuhan_khusus.length" color="green">Tidak</a-tag>
        <template v-else>
          <a-tag class="m-1" v-for="(kebutuhan, i) in record.berkebutuhan_khusus" :key="i" color="orange">{{kebutuhan.deskripsi}}</a-tag>
        </template>
      </template>
    </span>
  </a-table>
</template>

<script>
import { disabledList, worksList, educationsList, monthlyRevenueList } from '@/helpers/listsPpdbSelect'
const columns = [
  {
    title: 'Hubungan',
    dataIndex: 'tipe',
    align: 'center',
    width: 150,
    scopedSlots: {
      customRender: 'hubungan',
    },
    fixed: 'left',
  },
  {
    title: 'Nama',
    dataIndex: 'nama',
    align: 'center',
    width: 300,
    scopedSlots: {
      customRender: 'nullStr',
    },
  },
  {
    title: 'NIK',
    dataIndex: 'nik',
    align: 'center',
    width: 200,
    scopedSlots: {
      customRender: 'nullStr',
    },
  },
  {
    title: 'Tahun Lahir',
    dataIndex: 'tahun_lahir',
    align: 'center',
    width: 100,
    scopedSlots: {
      customRender: 'nullStr',
    },
  },
  {
    title: 'Pendidikan',
    dataIndex: 'pendidikan_terakhir',
    align: 'center',
    width: 200,
    scopedSlots: {
      customRender: 'pendidikan',
    },
  },
  {
    title: 'Penghasilan Bulanan',
    dataIndex: 'penghasilan_bulanan',
    align: 'center',
    width: 250,
    scopedSlots: {
      customRender: 'penghasilan',
    },
  },
  {
    title: 'Berkebutuhan Khusus',
    dataIndex: 'berkebutuhan_khusus',
    align: 'center',
    width: 300,
    scopedSlots: {
      customRender: 'berkebutuhan_khusus',
    },
  },
]
export default {
  data() {
    return {
      columns,
      loadingTable: false,
      data: [
        { key: 1, tipe: 'ayah' },
        { key: 2, tipe: 'ibu' },
        { key: 3, tipe: 'wali' },
      ],
      worksList,
      disabledList,
      monthlyRevenueList,
      educationsList,
    }
  },
  props: {
    datas: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.data = this.datas.orangtuas.map(data => {
      return {
        key: data.id,
        ...data,
      }
    })
  },
  methods: {
    showHubungan(value) {
      if (value === 'ayah') {
        return 'Ayah Kandung'
      } else if (value === 'ibu') {
        return 'Ibu Kandung'
      } else if (value === 'wali') {
        return 'Wali'
      }
    },
    setValueTable(value, anchor) {
      const dataTitle = this[anchor].find(data => data.name === value)
      // console.log(value, dataTitle)
      if (dataTitle) return dataTitle.title
      return value
    },
  },
}
</script>

<style>

</style>
